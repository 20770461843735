import React, { useState, useEffect } from 'react';
import './Noticias.css';
import noticia1 from '../../assets/fondonoticias1.jpg';
import noticia2 from '../../assets/fondoplantilla.jpg'; 
import noticia3 from '../../assets/fondonoticias3.jpg';
import noticia4 from '../../assets/fondonoticias4.jpg';

import noticia0212_1 from '../../assets/noticias/noticia021224_1.png';
import noticia2511_1 from '../../assets/noticias/noticia25-11-1.png';

import noticia2910_1 from '../../assets/noticias/noticia29-10-1.jpg';
import noticia2910_2 from '../../assets/noticias/noticia29-10-2.jpg';
import noticia2910_3 from '../../assets/noticias/noticia29-10-3.jpg';

import noticia1111_1 from '../../assets/noticias/noticia11-11-1.png';

import noticia1811_1 from '../../assets/noticias/noticia1811_1.jpg';

// Componente Modal para mostrar la noticia completa
const Modal = ({ show, onClose, title, date, content }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal-noticias">
        <h2>{title}</h2>
        <p className="modal-date">{date}</p> {/* Fecha en el modal */}
        <div dangerouslySetInnerHTML={{ __html: content }} /> {/* Renderizar contenido HTML */}
        <button className="close-button" onClick={onClose}>Cerrar</button>
      </div>
    </div>
  );
};

const Card = ({ id, name = '', date = '', imageUrl, isOpen, handleClick, isMobile, onOpenModal }) => {
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (!isMobile) {
      setIsHovered(false);
    }
  }, [isMobile]);

  const handleMouseEnter = () => {
    if (!isMobile) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      setIsHovered(false);
    }
  };

  return (
    <div
      className={`card ${isHovered || isOpen ? 'open' : ''}`}
      onClick={() => isMobile && handleClick(id)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ 
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#fff',
        flex: isHovered || isOpen ? 2 : 1,
        position: 'relative'
      }}
    >
      <div className="card-overlay"></div>
      <div className="card-content">
        <h2 className="card-title">{name}</h2>
        <p className="card-date">{date}</p> {/* Fecha en la carta */}
        <button className="card-button" onClick={() => onOpenModal(id)}>Ver noticia completa</button>
      </div>
    </div>
  );
};

const Gallery = () => {
  const [openCardId, setOpenCardId] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({ title: '', date: '', content: '' });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClick = (id) => {
    setOpenCardId(openCardId === id ? null : id);
  };

  // Contenido de las noticias
  const noticias = {
    '1': {
      title: 'Crónica 2 diciembre',
      date: '2 de diciembre de 2024',
      content: `
        <p>La fortuna de nuevo le volvió a ser esquiva a los de Pablo Serrano. Y es que cuando parecía que sumarían su primera victoria de la temporada en su duelo en Hellín, un triple de José Juan Andújar le daba a los hellineros la oportunidad de utilizar la prórroga para sumar la cuarta para ellos en el tiempo extra, circunstancia que no desaprovecharon</p>
        <p>Nuestro equipo juvenil se desplazó hasta Puertollano, el partido estuvo muy igualado. El equipo local inicio el partido tomando el dominio en puntuación pero los de Ricky no tardaron en despertar y empezaron a dominar el resto del partido demostrando su experiencia de dominio en pista y bloqueando al rival en todo momento.</p>
        <img src="${noticia0212_1}" alt="Noticia" style="width:100%; margin-top: 20px;" />
      `
    },
    '2': {
      title: 'Crónica 25 noviembre',
      date: '25 de noviembre de 2024',
      content: `
        <p>Antes de exponer las crónicas de este fin de semana queremos expresar nuestro más rotundo rechazo contra la violencia de género. Nuestro club también decimos NO a esta lucha constante que tenemos.</p>
        <p>El domingo se desplazo nuestro equipo senior hasta Almagro donde tuvo lugar el enfrentamiento. El inicio del partido lo dominó el equipo local hasta que los nuestros despertaron y acortaron distancias plantándole cara al rival. Desde ese momento el partido estuvo muy igualado pero la victoria no pudo ser otra jornada más.</p>
        <p>El sábado nuestro equipo juvenil disputo su partido en casa, el partido empezó siendo dominado por el equipo local, pero en todo momento los tomelloseros peleaban para poder frenarlos y hacerles más difíciles las entradas, pero eso no pudo con los nuestro y otra vez más demostraron su dominio en pista.</p>
        <img src="${noticia2511_1}" alt="Noticia" style="width:100%; margin-top: 20px;" />

        `
    },
    '3': {
      title: 'Crónica 18 de noviembre',
      date: '18 de noviembre de 2024',
      content: `
      <p>EQUIPO SENIOR</p>
      <p>Paridad casi total en un primer cuarto que daba paso al control absoluto en la media hora final del partido para el filial cervantino.</p>
      <p>Victoria clara para los amarillos que no dejaron escapar la posibilidad de sumar la tercera del año y dejar a los manzanareños sin triunfos tras 5 fechas de competición</p>
      <p>EQUIPO JUVENIL</p>
      <p>El equipo juvenil se desplazó hasta Argamasilla de alba para disputar su enfrentamiento que tubo inicio a las 16h.</p>
      <p>En dicho enfrentamiento el equipo visitante demostró su dominio en pista y bloqueo al equipo local.</p>
      <p>Los de Ricky no permitieron al equipo argamasillero superarlos en ningún periodo.</p>
      <img src="${noticia1811_1}" alt="Noticia" style="width:100%; margin-top: 20px;" />
      `
    },
    '4': {
      title: 'Crónica 11 de noviembre',
      date: '11 de noviembre de 2024',
      content: `
       <p>Gran victoria para los de Rubén Córcoles que este choque tuvieron que lidiar con un Manzanares que salió respondón al buen inicio de los locales en el primer cuarto y que poco a poco fueron remontando hasta colocarse a solo un punto a tres minutos del final. Faltó ese punch final para consumar la proeza. Los albaceteños estuvieron fuertes en su convicción para lograr mantenerse invictos una jornada más mientras que los de Pablo Serrano siguen sin estrenarse.</p>
        <p>Jugador destacado: Sergio Sánchez-Migallón -- 25 puntos 1 Triple</p>
       <p>Gran partido el disputado en el Caba, ambos equipos demostraron su dominio en pista desde el principio del partido. Estuvo bastante reñido puesto que en cada cuarto se cambiaba las ventajas en el marcador. Al final la victoria se la llevo el equipo miguelturreño.</p>
       <p>Jugador destacado: Gustavo Rodríguez 18 puntos</p>

      <img src="${noticia1111_1}" alt="Noticia" style="width:100%; margin-top: 20px;" />
      `
    }
  };

  const handleOpenModal = (id) => {
    const { title, date, content } = noticias[id];
    setModalData({ title, date, content });
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <section className="gallery-container" id="galeria">
      <Card 
        id="1"
        name="Crónica 2 diciembre"
        date="2 de diciembre de 2024"
        imageUrl={noticia3}
        isOpen={openCardId === '1'}
        handleClick={handleClick}
        isMobile={isMobile}
        onOpenModal={handleOpenModal}
      />
      <Card 
        id="2"
        name="Crónica 25 noviembre"
        date="25 de noviembre de 2024"
        imageUrl={noticia4}
        isOpen={openCardId === '2'}
        handleClick={handleClick}
        isMobile={isMobile}
        onOpenModal={handleOpenModal}
      /> 
      <Card 
        id="3"
        name="Crónica 18 noviembre"
        date="18 de noviembre de 2024"
        imageUrl={noticia1}
        isOpen={openCardId === '3'}
        handleClick={handleClick}
        isMobile={isMobile}
        onOpenModal={handleOpenModal}
      />
        <Card 
        id="4"
        name="Crónica 11 noviembre"
        date="11 de noviembre de 2024"
        imageUrl={noticia2}
        isOpen={openCardId === '4'}
        handleClick={handleClick}
        isMobile={isMobile}
        onOpenModal={handleOpenModal}
      />
      
        

      {/* Modal para mostrar la noticia completa */}
      <Modal 
        show={showModal} 
        onClose={handleCloseModal} 
        title={modalData.title} 
        date={modalData.date} 
        content={modalData.content} 
      />
    </section>
  );
};

export default Gallery;
