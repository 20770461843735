import React, { useState, useEffect } from 'react';
import cbmanzanares from '../../assets/logo.jpeg';
import daimiel from '../../assets/escudos_equipos/daimiel.png';
import argamasilla from '../../assets/escudos_equipos/argamasilla.jpg';
import criptana from '../../assets/escudos_equipos/criptana.jpg';
import valdepenas from '../../assets/escudos_equipos/valdepenas.png';
import socuellamos from '../../assets/escudos_equipos/socuellamos.png';
import tomelloso from '../../assets/escudos_equipos/tomelloso.png';
import alcazar from '../../assets/escudos_equipos/alcazar.png';

const ResultadosPartidos = () => {
  const [isMobile, setIsMobile] = useState(false);

  // Datos de partidos simulados
  const partidos = [
    {
      jornada: 1,
      local: { nombre: 'Alevín Fem. Daimiel', logo: daimiel, puntos: 48 },
      visitante: { nombre: 'Alevín Fem. Manzanares', logo: cbmanzanares, puntos: 12 },
    },
    {
      jornada: 1,
      local: { nombre: 'Alevín "B" Daimiel', logo: daimiel, puntos: 54 },
      visitante: { nombre: 'Alevín "B" Manzanares', logo: cbmanzanares, puntos: 14 },
    },
    {
      jornada: 1,
      local: { nombre: 'Alevín "A" Daimiel', logo: daimiel, puntos: 32 },
      visitante: { nombre: 'Alevín "A" Manzanares', logo: cbmanzanares, puntos: 35 },
    },
    {
      jornada: 2,
      local: { nombre: 'Cadete Masc. Manzanares', logo: cbmanzanares, puntos: 77 },
      visitante: { nombre: 'AYTO. Daimiel', logo: daimiel, puntos: 43 },
    },
    {
      jornada: 2,
      local: { nombre: 'Infantil Fem. Manzanares', logo: cbmanzanares, puntos: 22 },
      visitante: { nombre: 'AYTO. Daimiel', logo: daimiel, puntos: 42 },
    },

    {
      jornada: 3,
      local: { nombre: 'Cabezuelo CB Socuellamos', logo: socuellamos, puntos: 21 },
      visitante: { nombre: 'Alevín "A" Manzanares', logo: cbmanzanares, puntos: 62 },
    },
    {
      jornada: 3,
      local: { nombre: 'ED Argamasilla de Alba', logo: argamasilla, puntos: 48 },
      visitante: { nombre: 'Alevín "B" Manzanares', logo: cbmanzanares, puntos: 24 },
    },
    {
      jornada: 3,
      local: { nombre: 'Infantil Masc. Manzanares', logo: cbmanzanares, puntos: 2 },
      visitante: { nombre: 'AYTO. Valdepeñas', logo: valdepenas, puntos: 42 },
    },
    {
      jornada: 3,
      local: { nombre: 'Ayuntamiento de criptana', logo: criptana, puntos: 30 },
      visitante: { nombre: 'Infantil Fem. Manzanares', logo: cbmanzanares, puntos: 34 },
    },
    {
      jornada: 3,
      local: { nombre: 'ED Argamasilla de Alba', logo: argamasilla, puntos: 37 },
      visitante: { nombre: 'Cadete Manzanares', logo: cbmanzanares, puntos: 77 },
    },
    {
      jornada: 4,
      local: { nombre: 'Alevín "A" Manzanares', logo: cbmanzanares, puntos: 35 },
      visitante: { nombre: 'ED Argamasilla de Alba', logo: argamasilla, puntos: 22 },
    },
    {
      jornada: 4,
      local: { nombre: 'AYTO. Tomelloso', logo: tomelloso, puntos: 58 },
      visitante: { nombre: 'Alevín "B" Manzanares', logo: cbmanzanares, puntos: 18 },
    },
    {
      jornada: 4,
      local: { nombre: 'ED Criptana', logo: criptana, puntos: 56 },
      visitante: { nombre: 'Alevín Fem. Manzanares', logo: cbmanzanares, puntos: 16 },
    },
    {
      jornada: 4,
      local: { nombre: 'AYTO. Valdepeñas', logo: valdepenas, puntos: 40 },
      visitante: { nombre: 'Infantil Manzanares', logo: cbmanzanares, puntos: 44 },
    },
    {
      jornada: 4,
      local: { nombre: 'AYTO. Valdepeñas', logo: valdepenas, puntos: 43 },
      visitante: { nombre: 'Cadete Manzanares', logo: cbmanzanares, puntos: 57 },
    },

    {
      jornada: 5,
      local: { nombre: 'Alevin B Manzanares', logo: cbmanzanares, puntos: 11 },
      visitante: { nombre: 'Alevin A Manzanares', logo: cbmanzanares, puntos: 51 },
    },
    {
      jornada: 5,
      local: { nombre: 'Alevin Fem. Manzanares', logo: cbmanzanares, puntos: 35 },
      visitante: { nombre: 'AYTO. Tomelloso', logo: tomelloso, puntos: 18 },
    },
    {
      jornada: 5,
      local: { nombre: 'Infantil Masc. Manzanares', logo: cbmanzanares, puntos: 49 },
      visitante: { nombre: 'EDM Daimiel Azul', logo: daimiel, puntos: 45 },
    },
    {
      jornada: 5,
      local: { nombre: 'Grupo 76 Alkasar negro', logo: alcazar, puntos: 16 },
      visitante: { nombre: 'Infantil Fen. Manzanares', logo: daimiel, puntos: 24 },
    },
    {
      jornada: 5,
      local: { nombre: 'Cadete Masc. Manzanares', logo: cbmanzanares, puntos: 55 },
      visitante: { nombre: 'AYTO. Valdepeñas', logo: valdepenas, puntos: 43 },
    }
    

    // Añadir más partidos/jornadas aquí

  ];

  // Inicializa jornadaActual con la última jornada disponible
  const [jornadaActual, setJornadaActual] = useState(partidos[partidos.length - 1].jornada);

  // Función para cambiar la jornada
  const cambiarJornada = (direccion) => {
    if (direccion === 'previa' && jornadaActual > 1) {
      setJornadaActual(jornadaActual - 1);
    } else if (direccion === 'siguiente' && jornadaActual < partidos[partidos.length - 1].jornada) {
      setJornadaActual(jornadaActual + 1);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 480);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Filtrar los partidos de la jornada actual
  const partidosDeJornadaActual = partidos.filter(p => p.jornada === jornadaActual);

  return (
    <div style={styles.container}>
      <p style={styles.categoria}>RESULTADOS - DEPORTE BASE</p>

      <div style={styles.resultadosContainer}>
        {/* Botón de jornada anterior */}
        <button onClick={() => cambiarJornada('previa')} style={styles.button}>
          {'<'}
        </button>

        {/* Mostrar el número de la jornada */}
        <h2 style={styles.jornada}>Jornada {jornadaActual}</h2>

        {/* Botón de jornada siguiente */}
        <button onClick={() => cambiarJornada('siguiente')} style={styles.button}>
          {'>'}
        </button>
      </div>

      {/* Contenedor horizontal de partidos */}
      <div style={styles.partidosContainer}>
        {partidosDeJornadaActual.map((partido, index) => (
          <div key={index} style={styles.partido}>
            <div style={styles.equipo}>
              <img src={partido.local.logo} alt={partido.local.nombre} style={styles.logo} />
              <p>{partido.local.nombre}</p>
              <p style={styles.puntos}>{partido.local.puntos}</p>
            </div>

            <p style={styles.guion}>-</p>

            <div style={styles.equipo}>
              <img src={partido.visitante.logo} alt={partido.visitante.nombre} style={styles.logo} />
              <p>{partido.visitante.nombre}</p>
              <p style={styles.puntos}>{partido.visitante.puntos}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'auto',
    padding: '20px',
    backgroundColor: '#20448c',
    backgroundImage: 'linear-gradient(to bottom right, #3e32ed, #16aef0)',
    color: '#FFFFFF',
    textAlign: 'center',
  },
  resultadosContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
  },
  jornada: {
    fontSize: '24px',
    margin: '0 20px',
  },
  categoria: {
    fontSize: '40px',
    marginBottom: '20px',
    fontWeight: 'bold',
  },
  button: {
    backgroundColor: 'white',
    border: 'none',
    color: '#20448c',
    fontSize: '24px',
    padding: '10px',
    cursor: 'pointer',
    borderRadius: '30%',
  },
  partidosContainer: {
    display: 'flex', // Cambiar a flex para disposición horizontal
    flexDirection: 'row', // Disposición de los partidos en línea horizontal
    justifyContent: 'center',
    flexWrap: 'wrap', // Esto permite que los partidos se acomoden en múltiples líneas si no caben en una sola
    marginTop: '20px',
  },
  partido: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 50px',
    marginBottom: '10px',
  },
  equipo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 20px',
  },
  logo: {
    width: '50px',
    height: '50px',
  },
  puntos: {
    fontSize: '30px',
    fontWeight: 'bold',
  },
  guion: {
    fontSize: '50px',
    margin: '0 20px',
  },
};

export default ResultadosPartidos;
